var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"group"}},[_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: false,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'ActionFunction')?_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Thêm người lao động'),expression:"'Thêm người lao động'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body ml-2",attrs:{"icon":"PlusCircleIcon","size":"18"},on:{"click":function($event){return _vm.showModal(props.row.id)}}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }