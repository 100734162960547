<template>
  <div
    id="group"
    class="page-container-table"
  >

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã người dùng -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span>{{ props.row.name }}</span>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">

          <feather-icon
            v-b-tooltip.hover.top="'Thêm người lao động'"
            icon="PlusCircleIcon"
            size="18"
            class="text-body ml-2"
            @click="showModal(props.row.id)"
          />

        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>

import {
  VBTooltip,
} from 'bootstrap-vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/Constants'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataList: [],
      columns: [
        {
          label: 'TÊN NHÓM ĐỐI TƯỢNG HUẤN LUYỆN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'SỐ NGƯỜI TRONG NHÓM',
          field: 'amount',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.$showLoading()
      const res = await axiosApiInstance.get(ConstantsApi.API_TRAINING)
      this.dataList = res.data.data
      this.$hideLoading()
    },
    showModal(id) {
      this.$router.push({ name: 'target-group-training', params: { id } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
#group {
.search-input {
    width: 400px;
  }
 .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
